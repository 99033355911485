import React, { useState, useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import Particle from "../Particle";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

import nudeglass from "../../Assets/Projects/nudeglass.png";
import laundrysauce from "../../Assets/Projects/laundrysauce.png";
import ionemoto from "../../Assets/Projects/ionemoto.png";
import joinus from "../../Assets/Projects/joinus.png";
import wwc from "../../Assets/Projects/wwc.png";
import ketlmtn from "../../Assets/Projects/ketlmtn.png";
import radian from "../../Assets/Projects/radian.png";
import getcho from "../../Assets/Projects/getcho.png";
import sazy from "../../Assets/Projects/sazy.png";
import kth from "../../Assets/Projects/kth.png";
import eqtuning from "../../Assets/Projects/eqtuning.png";
import lucky from "../../Assets/Projects/lucky.png";
import aabaco from "../../Assets/Projects/aabaco.png";
import blufenix from "../../Assets/Projects/blufenix.png";

function ShopifyPortfolio() {
  const [width, setWidth] = useState(1200);

  useEffect(() => {
    setWidth(window.innerWidth);
  }, []);

  return (
    <div>
      <Container fluid className="resume-section">


        <Row style={{textAlign: "center", listStyleType: "none"}} className="py-5 text-left">
          <h1 className="mb-3 d-block">Shopify References:</h1>
          <p>My last public app project: <a href="https://apps.shopify.com/wizy-sitemap-manager" target="_blank" className="text-bg-danger">https://apps.shopify.com/wizy-sitemap-manager</a>  </p><br/>
          <p>My last Shopify store project: <a href="https://laundrysauce.com" target="_blank" className="text-bg-danger">https://laundrysauce.com</a> </p>
          <div style={{width: "100%", display: "block"}}>

            <div>
              <div style={{marginBottom: 10}}>
                <a href="https://us.nudeglass.com" target="_blank" title={"nudeglass.com"}>
                  <img src={nudeglass} alt={"nudeglass.com"} style={{width: "100%"}}/>
                </a>
              </div>

              <div style={{marginBottom: 10}}>
                <a href="https://laundrysauce.com" target="_blank" title={"laundrysauce.com"}>
                  <img src={laundrysauce} alt={"laundrysauce.com"} style={{width: "100%"}}/>
                </a>
              </div>

              <div style={{marginBottom: 10}}>
                <a href="https://ionemoto.com" target="_blank" title={"ionemoto.com"}>
                  <img src={ionemoto} alt={"ionemoto.com"} style={{width: "100%"}}/>
                </a>
              </div>

              <div style={{marginBottom: 10}}>
                <a href="https://joinus.com.tr" target="_blank" title={"joinus.com.tr"}>
                  <img src={joinus} alt={"joinus.com.tr"} style={{width: "100%"}}/>
                </a>
              </div>

              <div style={{marginBottom: 10}}>
                <a href="https://worldwidecyclery.com" target="_blank" title={"worldwidecyclery.com"}>
                  <img src={wwc} alt={"worldwidecyclery.com"} style={{width: "100%"}}/>
                </a>
              </div>

              <div style={{marginBottom: 10}}>
                <a href="https://ketlmtn.com" target="_blank" title={"ketlmtn.com"}>
                  <img src={ketlmtn} alt={"ketlmtn.com"} style={{width: "100%"}}/>
                </a>
              </div>

              <div style={{marginBottom: 10}}>
                <a href="https://radianjeans.com" target="_blank" title={"radianjeans.com"}>
                  <img src={radian} alt={"radianjeans.com"} style={{width: "100%"}}/>
                </a>
              </div>

              <div style={{marginBottom: 10}}>
                <a href="https://getchostore.com" target="_blank" title={"getchostore.com"}>
                  <img src={getcho} alt={"getchostore.com"} style={{width: "100%"}}/>
                </a>
              </div>

              <div style={{marginBottom: 10}}>
                <a href="https://sazy.com" target="_blank" title={"sazy.com"}>
                  <img src={sazy} alt={"sazy.com"} style={{width: "100%"}}/>
                </a>
              </div>

              <div style={{marginBottom: 10}}>
                <a href="https://kissthehippo.com" target="_blank" title={"kissthehippo.com"}>
                  <img src={kth} alt={"kissthehippo.com"} style={{width: "100%"}}/>
                </a>
              </div>

              <div style={{marginBottom: 10}}>
                <a href="https://eqtuning.com" target="_blank" title={"eqtuning.com"}>
                  <img src={eqtuning} alt={"eqtuning.com"} style={{width: "100%"}}/>
                </a>
              </div>

              <div style={{marginBottom: 10}}>
                <a href="https://lucky-teeth.com" target="_blank" title={"lucky-teeth.com"}>
                  <img src={lucky} alt={"lucky-teeth.com"} style={{width: "100%"}}/>
                </a>
              </div>

              <div style={{marginBottom: 10}}>
                <a href="https://aabaco.com" target="_blank" title={"blufenix.com.tr"}>
                  <img src={aabaco} alt={"aabaco.com"} style={{width: "100%"}}/>
                </a>
              </div>

              <div style={{marginBottom: 10}}>
                <a href="https://blufenix.com.tr" target="_blank">
                  <img src={blufenix} alt={"blufenix.com.tr"} style={{width: "100%"}}/>
                </a>
              </div>

            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
}

export default ShopifyPortfolio;
